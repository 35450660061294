window._ = require('lodash');

import {createPopper} from '@popperjs/core';

window.createPopper = createPopper;

// import tippy from 'tippy.js';
// import 'tippy.js/dist/tippy.css';
//
// window.tippy = tippy;
//
//
// import Dropzone from "dropzone";
//
// window.Dropzone = Dropzone;
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
//
// import Echo from "laravel-echo"
//
// window.Pusher = require('pusher-js');
//
// if (process.env.MIX_PUSHER_APP_KEY) {
//     const options = {
//         broadcaster: 'pusher',
//         key: process.env.MIX_PUSHER_APP_KEY,
//         cluster: 'eu',
//         forceTLS: true,
//         encrypted: true
//     };
//
//     window.Echo = new Echo(options);
// }
